<template>
    <v-card class="pa-3" max-width="800">
        <h1>{{ $t("requestPasswordReset.title") }}</h1>
        <v-subheader>{{ $t("requestPasswordReset.subheader") }}</v-subheader>
        
        <validation-observer
            ref="observer"
        >
            <v-form @submit.prevent="submit">
                <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    name="email"
                >
                    <div>
                        <v-text-field
                            v-model="form.email"
                            :label="$t('requestPasswordReset.label_email')"
                            :error-messages="errors"
                            required
                            type="email" 
                            name="email" 
                            id="email"
                        ></v-text-field>
                    </div>
                </validation-provider>
                <div>
                    <v-btn type="submit" name="submit" value="submit">{{ $t("requestPasswordReset.label_submit") }}</v-btn>
                </div>
            </v-form>
        </validation-observer>
        <v-snackbar v-model="confirmSubmit" >
            {{ confirmSubmitText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue darken-3"
                v-bind="attrs"
                @click="confirmSubmit = false"
                >
                    {{ $t("requestPasswordReset.close") }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode, configure } from 'vee-validate';
import { required, email} from 'vee-validate/dist/rules'
import { ILLI_AUTH_SERVER_URL } from '../config'
export default Vue.extend({
    name: "requestPasswordReset",
    components: {
        ValidationProvider,
        ValidationObserver,
    },

    data () {
        return ({
            confirmSubmit: false,
            confirmSubmitText: "",
            form: {
                email: '',
            }
        })
    },
    beforeMount () {
        setInteractionMode('eager')
        extend('required', required)
        extend('email', email)
        configure({
            defaultMessage: (field, values) => {
                values._field_ = this.$t(`requestPasswordReset.label_${field}`);
                return this.$t(`validations.messages.${values._rule_}`, values);
            }
        });
    },
    methods: {
        submit() {
            this.$refs.observer.validate()
            axios
            .post(`${ILLI_AUTH_SERVER_URL}/requestPasswordReset`, { email: this.form.email})
            .then(response => {
                this.confirmSubmitText = (response.status === 202) ? `${this.$t("requestPasswordReset.sent")}` : `${this.$t("requestPasswordReset.error")}`
                this.confirmSubmit = true
            })
            .catch(error => {
                if(error.response.status === 400) { 
                    this.confirmSubmitText = this.$t("requestPasswordReset.no_email")
                }
                if(error.response.status === 500) this.confirmSubmitText = this.$t("requestPasswordReset.error")
                this.confirmSubmit = true
            })
        }
    }
})
</script>